import React from 'react'
import cn from 'classnames'
import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'
import Link from '../../components/Common/Link'
import * as s from '../../pages/news.module.scss'

const PartnerBimInPractice2024: React.FC = () => {
  return (
    <>
      <SEO
        title='SIGNAL стал партнером конференции "BIM на практике 2024"'
        description='"13 сентября 2024 года состоится конференция  "BIM на практике 2024", частью которой будет SIGNAL. Темы конференции затронут области строительства - импортозамещение, искусственный интеллект, девелопмент и управление."'
      />
      <NewsSectionBlock date="09.09.2024">
        <Typography variant="h1" color="blue">
          SIGNAL стал партнером конференции
          "BIM&nbsp;на&nbsp;практике&nbsp;2024"
        </Typography>
        <Typography variant="body1">
          Рады сообщить, что мы стали партнером самого яркого события
          строительной отрасли{' '}
          <Link
            href="https://biminpractice.ru/"
            rel="nofollow noreferrer"
            target="_blank"
          >
            "BIM&nbsp;на&nbsp;практике&nbsp;2024"
          </Link>{' '}
          которое пройдет 13&nbsp;сентября 2024 в Санкт&#8209;Петербурге!
        </Typography>
        <Typography variant="body1">
          Cамое яркое событие строительной отрасли года –{' '}
          <Link
            href="https://biminpractice.ru/"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Конференция «BIM&nbsp;на&nbsp;практике&nbsp;2024»
          </Link>{' '}
          – пройдёт в этом году 13 сентября 2024. Мероприятие традиционно
          проводится под эгидой компании ПСС, лидера в стратегическом
          консалтинге. Конференция станет местом сбора для лидеров ТИМ и
          экспертов по цифровизации, где обсуждаются актуальные тенденции в
          цифровизации бизнес-процессов в строительной отрасли, представляются
          передовые практики применения ТИМ и создаётся уникальная среда для
          обмена опытом между профессионалами: проектировщиками, строителями и
          заказчиками строительства ОКС.
        </Typography>
        <Typography variant="body1">
          Программа Конференции охватит разные направления цифровизации
          строительной отрасли, а также покажет участникам путь новых
          возможностей. Эксперты поделятся, как улучшить свои профессиональные
          навыки и увеличить конкурентоспособности компании.
        </Typography>
        <Typography variant="h3">Темы Конференции:</Typography>
        <ol className={cn(s.textLists, s.decimal)}>
          <li>
            <Typography variant="body1">
              Импортозамещение в ТИМ: опыт и результаты;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Подготовка специалистов для цифровизации и технологического
              суверенитета;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Искусственный интеллект и Роботы на строительной площадке;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Девелопмент и Управление строительством;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Среда Общих Данных: управление проектом, проектированием,
              безбумажная стройка и новая ценность BIM;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              BIM Запада и Востока: перспективы сосуществования.
            </Typography>
          </li>
        </ol>
        <Typography variant="h3">VIP-зал для C-level руководителей:</Typography>
        <Typography variant="body1">
          Для руководителей высшего звена компаний мы готовим отдельную
          программу и предлагаем специально оборудованный VIP-зал. Здесь будет
          представлена возможность пообщаться с лидерами отрасли и обсудить
          доклады для директоров и инвесторов.
        </Typography>
        <Typography variant="h3">Нетворкинг:</Typography>
        <Typography variant="body1">
          Все участники смогут расширить круг знакомств в профессиональной
          отраслевой среде и непосредственно обменяться мнениями в прямом
          общении.
        </Typography>
        <Typography variant="h3">Как попасть?</Typography>
        <Typography variant="body1">
          Получите доступ к эксклюзивному контенту, общению с экспертами и
          возможности внести свой вклад в будущее строительной отрасли!
        </Typography>
        <Typography variant="h3">
          Социальный проект конференции "BIM на практике"
        </Typography>
        <Typography variant="body1">
          Вся чистая прибыль от Конференции компания ПСС направит на развитие ИТ
          навыков детей и взрослых с ограниченными возможностями. Присоединяясь
          к Конференции, вы не только станете частью самого масштабного события
          этой осени от пионеров BIM в России, но и поддержите благородную
          миссию развития нашей страны.
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Вместе мы можем сделать больше!
        </Typography>
        <Link
          href="https://biminpractice.ru/"
          rel="nofollow noreferrer"
          target="_blank"
        >
          Зарегистрироваться
        </Link>
      </NewsSectionBlock>
    </>
  )
}

export default PartnerBimInPractice2024
